<template>
  <div class="w-screen h-full">
    <da-loader  v-bind:overlay="isLoading"></da-loader>
    <div class="w-screen h-full grid place-content-center">+

    <div>
      <lottie-animation
            :path="'lottie/searcherv2.json'"
            :width="300"
            :height="300"
            :speed="1"
        />
    </div>
    <div class="sm:w-80 md:w-96 lg:w-128 h-auto">
      
        <v-text-field
          label="Buscar Paciente"
          color="#E7AC18"
          filled
          outlined
          dense
          v-model="Param"
          @keypress="searchError = false"
          @keypress.enter.native="onSearch"
        ></v-text-field>
        <small v-show="searchError" class="text-red-600 text-sm">Ingresa un parametro de busqueda; Ej: 'Angeles'</small>
        <div class="flex justify-center mt-1">
          <da-button-900 @click.native="onSearch()" :label="'BUSCAR'" r2xl swpr class="w-36 py-2 mt-0 tracking-wider mr-5"></da-button-900>
           <router-link :to="'/px/new'">
             <da-button-primary  :label="'AGREGAR PX'" r2xl swpr class="w-36 py-2 mt-0 tracking-wider"></da-button-primary>
           </router-link>
        </div>
    </div>

    <div class="mt-10 mb-0 animate-fade-in-up" v-show="showResult">
      <div class="sm:w-85 lg:w-full">
        <div class="flex">
            <p class="">{{this.patients.length}} Resultados de la busqueda</p>
            <div class="flex justify-end " @click="onClearSearcher()">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">       
                    <v-icon color="grey-lighten-1">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Busqueda</span>
              </v-tooltip>
            </div>

        </div>
        <v-divider class="mb-3 text-grey"></v-divider>

        <div class="bg-primary-900 h-10 sm:w-85 lg:w-128 mb-2" v-for="(result,index) in patients" :key="index">
        
          <div class="flex">
            <div class="w-full">
              <div class="flex">
                <v-icon color="white" size="x-large" class="ml-2 sm:-mt-2 lg:-mt-2">mdi-account</v-icon>
                <p class="sm:text-xs lg:text-sm sm:mt-3 lg:mt-3 ml-1 text-white uppercase  ">{{result.name + ' ' + result.surname}}</p>
              </div>
            </div>
            <div class="flex justify-end w-10">
             <div @click="onSelect(result.id_person)" >
               <v-icon color="white" size="39" class="sm:mt-0 lg:-mt-0 cursor-pointer">mdi-menu-right</v-icon>
             </div>
            </div>
          </div>
        </div>

        <v-divider class="mt-3 text-grey"></v-divider>
      </div>
    </div>

    <div class="mt-10 pb-5">
          <div class="bg-primary-200 h-12 sm:w-85 lg:w-full mb-5 elevation-3" style="box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.25); border-radius: 12px;">
            <div class="flex pt-3">
            
              <div class="flex justify-start ml-5">
                <h6 class="text-primary-1000 sm:text-sm lg:text-base">Cumpleaños de pacientes</h6>
              </div>
              <div class="flex justify-end mr-5">   
                <h6 class="text-primary-1000"><strong class="sm:text-sm lg:text-base">Mes: {{mesActual}}</strong></h6> 
              </div>
            </div>
          </div>
          <div class="w-85 lg:w-full">
            <da-datatable :headers="headers" :values="birthdays" :elevation="3"></da-datatable>
          </div>
    </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
const primeraLetraMayuscula = (cadena) => cadena.charAt(0).toUpperCase().concat(cadena.substring(1, cadena.length));
export default {
    mixins: [VuexMixin],
    data: function() {
        return {
            headers: [
                { text: 'Nombre', value: 'name_complete' },
                { text: 'Fecha',  value: 'birthday' },
                { text: 'Edad',   value: 'age' }
            ],
            totalResults: 0,
            Param:        '',
            mesActual:    '',
            isLoading:    false,
            showResult:   false,
            searchError:  false,
            listPatient:  []
        }
    },
    created() {
      this.isLoading = true
      this.init()
      this.isLoading = false
    },
    computed: {
     ...mapGetters(moduleTypes.DASHBOARD, {
            birthdays: fnTypes.getBirthdays,
            hasErrorInService: fnTypes.hasErrorInService
       }),
     ...mapGetters(moduleTypes.DASHBOARD, {
          patients: fnTypes.getPatients,
          hasErrorInService: fnTypes.hasErrorInService
       }),
    },
    methods: {
      ...mapActions(moduleTypes.DASHBOARD, [
          fnTypes.getBirthdays
      ]),

      ...mapActions(moduleTypes.DASHBOARD, [
          fnTypes.getPatients
      ]),
    
      onSearch: async function() {
        if(this.Param == ''){
          this.searchError = true
          return 
        }

        this.isLoading = true
        var data = {
          'param': this.Param
        }
                await this.ActionWrapp(moduleTypes.DASHBOARD, fnTypes.getPatients, data)
                this.totalResults = this.patients.length
                this.Param = ''
                this.listPatient = this.patients
                this.isLoading = false
                this.showResult = true
      },

      onClearSearcher: function(){
        this.totalResults = 0
        this.listPatient = null
        this.showResult = false
      },

      onSelect: function(id) {
        // var t = id
        this.$router.push('/px/history/'+id)
      },

      init: async function() {
        this.mesActual = new Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date());
        this.mesActual = primeraLetraMayuscula(this.mesActual)
        await this.ActionWrapp(moduleTypes.DASHBOARD, fnTypes.getBirthdays)
      },
    }
}
</script>

<style>

</style>